import React from "react"
import style from "./style.module.scss"
// import inst from "../../img/inst.png"
import telegram from "../../img/telegram.png"
import whatsapp from "../../img/whatsapp.png"
import photo from '../../img/photo1.svg'

export default function Contacts() {
    return (
        <section className={style.header}>
            <div><img className={style.photo} src={photo} alt="Основное фото" /></div>
            <div><h2 className={style.text1}>Контакты</h2>
                <p className={style.text2}>Со мной можно связаться одним из способов.</p>
                <div className={style.cont}>
                    <a href="https://t.me/Anka_Chernysheva"><img className={style.icon} src={telegram} alt="иконтка телеграм" /></a>
                    <a href="https://wa.me/79108484886"><img className={style.icon} src={whatsapp} alt="иконтка ватсап" /></a></div></div>
        </section>

    )
}